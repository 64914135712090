<template>
    <div class="row">
      <b-modal static ref="open_to_settings_table" id="open_to_settings_table" hide-header hide-header-close size="sm"
      scrollable>
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-12 text-center">{{ $t('general.name') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          
            <draggable
              tag="transition-group"
              :list="fieldsToViewEdit"
              class="list-group"
              draggable=".item"
              :animation="100"
              @start="isDragging = true"
              @end="isDragging = false"
              :move="onMove"
            >
              <div
                class="list-group-item item"
                v-for="element in fieldsToViewEdit"
                :key="element.name"
              >
              <span class="col-6">{{ element.name }}</span>
              </div>
          </draggable>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button @click="closeModal"
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5">{{
            $t('general.give_up').toUpperCase() }}
          </button>
          <button @click="onSubmitToSaveSettingTable"
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer">{{ $t('general.save') }}
          </button>
        </div>
      </template>
    </b-modal>

      <div class="col-md-12">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <form @submit.prevent="filterResult">
              <div class="row align-items-end py-0 my-0 mx-0">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 my-2 row px-0 mx-0">
                  <input-filter
                      :helper-text="$t('general.search')"
                      title="general.search"
                      :placeholder="$t('general.search_filter')"
                      :model.sync="search"
                  ></input-filter>
                </div>
                <div
                    class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                    style="margin: 0 !important;">
                  <div class="col-12 pr-0">
                    <button @click="filterResult(null)"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                        $t('general.filter')
                      }}
                    </button>
                  </div>
                  <div class="col-12 pr-0">
                    <button @click="resetFilters"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                      {{ $t('general.clear') }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <div class="col-md-12">
        <dashboard-box class="pb-10">
          <template v-slot:preview>
            <div class="fixed" style="position: fixed !important; top: 290px; right: 15px; z-index: 999999;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
                <span v-html="getIconByKey('icons.waybill.edit', {
                  class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>
            <data-table
                class="relative"
                tableVariant="''"
                :hover="false"
                :outlined="false"
                :striped="false"
                :no-border-collapse="true"
                :borderless="true"
                :bordered="false"
                @hitBottom="onHitBottom"
                :items="formattedItem"
                :fields="fields"
                :per-page.sync="per_page"
                :page.sync="page"
            ></data-table>
            <div class="d-flex justify-content-end items-end fixed cursor-pointer" style="right: 10px; bottom: 10px;">
              <div class="w-60px h-60px" v-if="isUserGranted('InvoiceCustomApprovalUser', ['create'],false,true)"
                   @click="$router.push({name: createRouteName})">
                <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                    class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                  })">
                  </span>
              </div>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import DashboardBox from "@/assets/components/DashboardBox";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import DataTable from "@/assets/components/dataTable/DataTable";
  import moment from 'moment';
  import {LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
  import InputFilter from "@/assets/components/filters/InputFilter";
  import SelectFilter from "@/assets/components/filters/SelectFilter";
  import _ from "lodash";
  import draggable from 'vuedraggable'

  import {
    BASE_URL,
    ERROR,
    ITEMS,
    LOADING,
    DELETE_ITEM_BY_ID,
    SUCCESS,
    UPDATE_ITEM_BY_ID,
    SET_ITEMS,
    HANDLE_INFINITE_SCROLL
  } from "@/core/services/store/invoice-custom-approval-users/invoice_custom_approval_users.module";
  import store from "@/core/services/store";
  
  
  export default {
    name: "InvoiceCustomApprovalUsersIndex",
    components: {
      SelectFilter,
      InputFilter,
      DataTable,
      DashboardBox,
      draggable
    },
    computed: {
      error() {
        return store.getters[ERROR];
      },
      loading() {
        return store.getters[LOADING];
      },
      success() {
        return store.getters[SUCCESS];
      },
      items() {
        return store.getters[ITEMS];
      },
      filters() {
        return {
          per_page: this.per_page,
          page: this.page,
          search: this.search,
          order_by:'sort',
          sort:'asc'
        }
      },
      dragOptions() {
      return {
        animation: 0,
        group: "users",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
      formattedItem() {
        let results = [];
        this.fieldsToViewEdit=[];
        if (this.items === null || this.items.data === null || this.items.data === undefined) return [];
        for (let i = 0; i < this.items.data.length; i++) {
          let currentItem = this.items.data[i];
          let createdAt = currentItem.created_at ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT) : moment();
          this.fieldsToViewEdit.push({
            'id': currentItem.user.id,
            'name':currentItem.user.name,
            'sort':currentItem.sort,
          })
          results.push({
            id: currentItem.id,
            name:currentItem.user.name,
            approved_status_id:currentItem.approved.translation.name,
            reject_status_id:currentItem.reject.translation.name,
            sort:currentItem.sort,
            createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
          })
        }
        this.fieldsToViewEdit=_.sortBy(this.fieldsToViewEdit, ['sort']);
        return results;
      }
    },
    data() {
      return {
        page: 1,
        per_page: 25,
        search: '',
        state: null,
        fieldsToViewEdit:[],
        editable: true,
        isDragging: false,
        languages: [],
        editRouteName: 'management.invoice_custom_approval_users.edit',
        createRouteName: 'management.invoice_custom_approval_users.create',
        fields: [
          {
            key: 'id',
            label: this.$t('general.id'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'name',
            label: this.$t('general.name'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'sort',
            label: this.$t('general.sort'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'approved_status_id',
            label: this.$t('general.approved_status_id'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'reject_status_id',
            label: this.$t('general.reject_status_id'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'createdAt',
            label: this.$t('general.created_at'),
            sortable: false,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          }
        ],
      };
    },
    methods: {
      onHitBottom($state){
        if(this.loading) {
          return;
        }
        if ($state) {
          this.state = $state;
        }
        this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          filters: this.filters,
          url: BASE_URL,
          '$state': $state,
        }).then(result => {
          if(result.status && $state) {
            $state.loaded();
          }else{
            if('end' in result && $state){
              $state.complete();
            }
          }
        })
      },
      onMove() {
      },
      openPopToSettingTable() {
        this.$refs['open_to_settings_table'].show()
      },
      closeModal() {
      this.$refs["open_to_settings_table"].hide()
    },
      onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};

      let tableFields=Object.entries(_.cloneDeep(this.fieldsToViewEdit));
      for (let i = 0; i < tableFields.length; i++) {
        json[i] = {
          name: this.fieldsToViewEdit[i].name,
          id: this.fieldsToViewEdit[i].id,
          sort: i+1,
        };
      }

      let contents = {
        table_fields: json,
      }
      let payload = {
        url: BASE_URL + '/sortable',
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.filterResult();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
      filterResult() {
        let self = this;
        this.page = 1;
        this.filters.page = 1;
        this.onHitBottom();
        setTimeout(() => {
          self.state.reset();
        }, 100);
      },
      resetFilters() {
        this.search = '';
      }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("menu.management.invoice_custom_approval_users")}
      ]);
  
      if(!this.filterResult.length){
        setTimeout(this.filterResult, 500);
      }
      this.$store.commit(SET_ITEMS, null);
    },
    watch:{
        isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
          return;
        }
        this.$nextTick(() => {
          this.delayedDragging = false;
        });
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  